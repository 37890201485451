// Libraries
import { initializeApp } from 'firebase/app'
import { getAuth } from 'firebase/auth'

// ENV Variables
import ENV from '@/../env.json'

export default function () {
  const firebaseConfig = {
    apiKey: ENV.firebase.apiKey,
    authDomain: ENV.firebase.authDomain,
    projectId: ENV.firebase.projectId,
    storageBucket: ENV.firebase.storageBucket,
    messagingSenderId: ENV.firebase.messagingSenderId,
    appId: ENV.firebase.appId,
    measurementId: ENV.firebase.measurementId
  }

  const app = initializeApp(firebaseConfig)
  const auth = getAuth(app)

  return {
    app,
    auth
  }
}

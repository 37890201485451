<template>
  <div class="flex flex-col gap-4">
    <title_component>
      <template #title> {{ gt('title') }} </template>
      <template #subtitle>
        {{ gt('subtitle') }}
      </template>
    </title_component>

    <alert_get_subscription_component v-if="!can_i_play_the_units">
    </alert_get_subscription_component>

    <div class="flex flex-col gap-2" v-if="!loading && units.length > 0">
      <div class="flex flex-col gap-2">
        <template v-for="(unit, index) in units" :key="unit.id">
          <unit_component
            :unit="unit"
            :locked="unit_is_locked || unit.locked_by_progress"
            :player_on_finish="get_resources"
          />

          <alert_get_account_component
            v-if="index === 0 && !AuthUser.is_logged"
          >
          </alert_get_account_component>
        </template>
      </div>
    </div>

    <loading_component v-if="loading"></loading_component>

    <alert_component v-if="!loading && units.length === 0">
      <template #message>
        {{ gt('alert_no_units.message') }}
        <a :href="url({ path: '/suggestions' })">clic aquí</a>
      </template>
    </alert_component>

    <div class="flex flex-col text-xs text-slate-500">
      {{ gt('footer.note') }}
    </div>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const title_component = defineAsyncComponent(
    () => import('@/components/commons/title.vue')
  )

  const loading_component = defineAsyncComponent(
    () => import('@/components/commons/loading.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // Components
  import unit_component from '@/components/app/units/unit.vue'
  import alert_get_account_component from './alerts/get_account.vue'
  import alert_get_subscription_component from './alerts/get_subscription.vue'

  // Helpers
  import url from '@/helpers/url'

  // Stores
  import UserStore from '@/stores/user'

  // Libs
  import { toRefs, ref, onMounted, computed } from 'vue'
  import Route from '@/methods/for_routes'
  import Unit from '@/methods/for_units'
  import User from '@/methods/for_users'
  import { useI18n } from 'vue-i18n'

  // Variables
  const props = defineProps({
    route_id: {
      default: null
    }
  })

  const { t } = useI18n()
  const gt = (key) => t(`laravel.vue.pages.route.components.units.${key}`)

  const loading = ref(true)
  const permissions = ref({
    can_play_routes: true
  })
  const status_route = ref({})
  const status_units = ref([])
  const units = ref([])
  const { route_id } = toRefs(props)
  const AuthUser = UserStore()

  // Computed vars
  const can_i_play_the_units = computed(() => {
    return permissions.value?.can_play_routes === true
  })

  const unit_is_locked = computed(() => {
    return !can_i_play_the_units.value || !AuthUser.is_logged
  })

  // Methods
  const get_statuses = async () => {
    if (!AuthUser.is_logged) {
      return
    }

    const response = await Route.get_status({
      route_id: route_id.value
    })

    status_route.value = response.route
    status_units.value = response.units
  }

  const get_units_by_route_id = async () => {
    const { records } = await Unit.get({
      with_properties: ['order', 'audios', 'videos'],
      with_relations: ['createdBy'],
      filters: {
        route_id: route_id.value
      }
    })

    units.value = records
      .sort((a, b) => a.order - b.order)
      .map((unit, index, $units) => {
        let locked = false
        unit.status = status_units.value.find(
          (status) => status.unit_id === unit.id
        )

        if (index === 0) {
          unit.locked_by_progress = locked
          return unit
        }

        try {
          let status = status_units.value.find(
            (status) => status.unit_id === $units[index - 1].id
          )

          locked = status.progress < 100
        } catch (error) {
          locked = true
        }

        unit.locked_by_progress = locked
        return unit
      })
  }

  const get_current_user_permissions = async () => {
    if (!AuthUser.is_logged) {
      return
    }

    const response = await User.get_permissions()
    permissions.value = response.permissions
  }

  const get_resources = async () => {
    loading.value = true

    await get_statuses()
    await get_current_user_permissions()
    await get_units_by_route_id()

    loading.value = false
  }

  // onMounted
  onMounted(async () => {
    await get_resources()
  })
</script>

<template>
  <div class="container !max-w-[500px]">
    <div class="flex flex-col gap-4">
      <form_register_component
        :on_response="on_response"
      ></form_register_component>
    </div>
  </div>
</template>
<script setup>
  // Helpers
  import url from '@/helpers/url'

  // Stores
  import UserStore from '@/stores/user'

  // Components
  import form_register_component from '@/components/commons/forms/register.vue'

  // Variables
  const User = UserStore()

  // Methods
  const on_response = ({ success }) => {
    if (success) {
      const params = new URLSearchParams(window.location.search)
      const redirect_to = params.get('redirect_to') || '/routes'

      window.location.href = url({
        path: `/login`,
        params: {
          redirect_to,
          user_id: User.user.id,
          authorization: User.user.temporary_token
        }
      })
    }
  }
</script>

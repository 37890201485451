export default function () {
  const user_agent = navigator.userAgent.toLowerCase()
  const is_mobile =
    /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
      user_agent
    )
  const is_desktop = !is_mobile

  const os = (() => {
    if (/android/.test(user_agent)) return 'android'
    if (/iphone|ipad|ipod/.test(user_agent)) return 'ios'
    if (/windows/.test(user_agent)) return 'windows'
    if (/mac os/.test(user_agent)) return 'macos'
    if (/linux/.test(user_agent)) return 'linux'
    return 'other'
  })()

  const is_webview = (() => {
    const is_safari = /safari/.test(user_agent) && !/chrome/.test(user_agent)
    const is_ios = /iphone|ipad|ipod/.test(user_agent)
    const standalone = window.navigator.standalone
    const is_android_webview = is_mobile && /wv/.test(user_agent)
    return (is_ios && (standalone || !is_safari)) || is_android_webview
  })()

  return {
    is_webview,
    is_web: !is_webview,
    is_mobile,
    is_desktop,
    os
  }
}

// Libs
import Ajv from 'ajv'

// Helpers
import Request from '@/helpers/request'

// ENV Variables
import ENV from '@/../env.json'

// Stores
import AppStore from '@/stores/app'

export default class {
  App = null
  request = null

  constructor() {
    this.request = new Request()
    this.App = AppStore()
  }

  is_valid(name, schema, response) {
    const ajv = new Ajv()
    const { success } = response
    const schema_to_validate = {
      type: 'object',
      required: [],
      properties: []
    }

    if (!success) {
      schema_to_validate.required = [
        'success',
        ...(schema.error?.required ?? [])
      ]

      schema_to_validate.properties = {
        success: { type: 'boolean' },
        errors: { type: 'object' },
        message: { type: 'string' },
        ...(schema.error?.properties ?? {})
      }
    }

    if (success) {
      schema_to_validate.required = [
        'success',
        ...(schema.success?.required ?? [])
      ]

      schema_to_validate.properties = {
        success: { type: 'boolean' },
        message: { type: 'string' },
        ...(schema.success?.properties ?? {})
      }
    }

    const validate = ajv.compile(schema_to_validate)
    return validate(response)
  }

  async execute({
    name = '',
    method = 'get',
    target = '/',
    body = {},
    schema = null,
    serializers = {}
  }) {
    let response = await this.request[method](target, body)

    if (schema && !this.is_valid(name, schema, response)) {
      const debug = {
        error: ENV.enums.StatusCodes.SCHEMA_DID_NOT_MATCH,
        message: 'Backend API is providing a bad response.',
        debug: {
          name,
          method,
          target,
          body,
          schema,
          response
        }
      }

      if (ENV.debug === true) {
        console.debug(debug)
      }

      return {
        success: false,
        error: ENV.enums.StatusCodes.SCHEMA_DID_NOT_MATCH
      }
    }

    if (response.success === false && response.message === 'Unauthenticated.') {
      this.App.notification({
        title: 'Emm...',
        message: 'Inicia sesión nuevamente.',
        level: ENV.enums.AlertLevels.DANGER
      })

      setTimeout(() => {
        localStorage.removeItem('user')
        window.location.href = '/login'
      }, 1500)
    }

    Object.keys(serializers).forEach((apply_to) => {
      if (response[apply_to]) {
        response[apply_to] = serializers[apply_to](response[apply_to])
      }
    })

    return response
  }
}

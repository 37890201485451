<template>
  <form
    :class="['flex flex-col gap-2', ...(!clean ? ['card', 'p-4'] : [])]"
    @submit.prevent="register()"
  >
    <div class="w-full flex flex-col gap-2">
      <input_component
        :settings="form.inputs.email"
        @update:value="form.inputs.email.value = $event"
      />

      <input_component
        :settings="form.inputs.password"
        @update:value="form.inputs.password.value = $event"
      />
    </div>

    <div class="w-full flex flex-wrap">
      <input_component
        :settings="form.inputs.i_am_of_legal_age"
        @update:value="form.inputs.i_am_of_legal_age.value = $event"
      />
    </div>

    <div class="flex w-full">
      <button_component
        :classes="['btn', 'btn-success', 'w-full']"
        :loading="form.is_loading"
        :submittable="true"
      >
        <i class="fi fi-rr-paper-plane"></i>
        {{ gt('button_register') }}
      </button_component>
    </div>

    <p class="block text-center text-slate-500">
      {{ gt('text_or_register_with') }}
    </p>

    <div class="buttons flex gap-2">
      <button_component
        :classes="['btn', 'btn-google', 'w-full']"
        @click="register('google')"
        :loading="login_with_google_is_loading"
      >
        <i class="fi fi-brands-google"></i>
        {{ gt('button_register_with_google') }}
      </button_component>

      <button_component
        :classes="['btn', 'btn-dark', 'w-full']"
        @click="register('apple')"
        :loading="login_with_apple_is_loading"
        v-if="show_apple_login"
      >
        <i class="fi fi-brands-apple"></i>
        {{ gt('button_register_with_apple') }}
      </button_component>
    </div>

    <p class="text-slate-500 text-center">
      {{ gt('text_accept_terms') }}

      <a :href="url({ path: '/terms' })" target="_blank">
        {{ gt('link_terms_and_conditions') }}
      </a>

      {{ gt('text_and') }}

      <a :href="url({ path: '/privacy' })" target="_blank">
        {{ gt('link_privacy_policy') }}
      </a>
    </p>
  </form>

  <div class="flex flex-col" v-if="with_sign_in">
    <p class="text-slate-500 text-center">
      {{ gt('text_already_have_an_account') }}
      <a title="Inicia Sesión" :href="url({ path: '/login' })">
        {{ gt('link_login') }}
      </a>
    </p>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Helpers
  import url from '@/helpers/url'
  import get_form_state from '@/helpers/get_form_state'
  import login_with from './methods/commons/login_with'
  import get_device_environment from '@/helpers/get_device_environment'

  // Stores
  import UserStore from '@/stores/user'

  // Libs
  import { toRefs, ref, computed } from 'vue'
  import User from '@/methods/for_users'
  import { useI18n } from 'vue-i18n'

  // Props
  const props = defineProps({
    on_response: {
      default: null
    },

    with_sign_in: {
      type: Boolean,
      default: true
    },

    clean: {
      type: Boolean,
      default: false
    }
  })

  // Variables
  const { t } = useI18n()
  const gt = (key, args = {}) => t(`components.forms.register.${key}`, args)

  const { on_response } = toRefs(props)
  const login_with_google_is_loading = ref(false)
  const login_with_apple_is_loading = ref(false)
  const form = get_form_state('login', [
    {
      label: gt('form_label_email'),
      name: 'email',
      type: 'text'
    },
    {
      label: gt('form_label_password'),
      name: 'password',
      type: 'password'
    },
    {
      label: gt('form_label_i_am_of_legal_age'),
      name: 'i_am_of_legal_age',
      type: 'checkbox'
    }
  ])

  // Computed variables
  const show_apple_login = computed(() => {
    const { os } = get_device_environment()
    return os === 'ios' || os === 'macos'
  })

  // Methods
  const register = async function (provider = null) {
    let response = null
    let name = null
    let email = null
    let google_id = null
    let apple_id = null
    let photo = null

    form.is_loading = true

    if (provider === 'google') {
      login_with_google_is_loading.value = true
      response = await login_with('google')
    }

    if (provider === 'apple') {
      login_with_apple_is_loading.value = true
      response = await login_with('apple')
    }

    if (provider) {
      if (!response) {
        form.is_loading = false
        login_with_google_is_loading.value = false
        login_with_apple_is_loading.value = false
        return
      }

      name = response.name
      email = response.email
      photo = response.photo

      if (provider === 'google') {
        google_id = response.uid
      }

      if (provider === 'apple') {
        apple_id = response.uid
      }
    }

    if (form.inputs.i_am_of_legal_age.value === false) {
      form.inputs.i_am_of_legal_age.errors = [
        gt('form_error_legal_age_required')
      ]
      form.is_loading = false
      return
    }

    const { $form, success, errors, message, user } = await User.store({
      $form: form,
      append: {
        name,
        email,
        google_id,
        apple_id,
        photo,
        logged_by: provider,
        and_login: true
      }
    })

    form.value = $form

    if (success) {
      if (user) {
        UserStore().update(user)
      }

      if (on_response.value) {
        on_response.value({ success, errors, message, user })
      }
    }

    form.is_loading = false
    login_with_google_is_loading.value = false
    login_with_apple_is_loading.value = false
  }
</script>

// Wrappers
import StoreWrapper from './helpers/wrappers/store'

// Services
import TrackService from '@/services/track'

export default {
  /**
   * Stores a new resource.
   */
  async store(properties = {}) {
    const session_id = localStorage.getItem('session_id', null)

    if (!session_id) {
      return {
        success: false
      }
    }

    return await StoreWrapper(new TrackService().store, {
      append: {
        session_id,
        ...properties
      }
    })
  }
}

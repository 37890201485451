// Helpers
import get_device_environment from '@/helpers/get_device_environment'

const spanish_speaking_countries = [
  'AR',
  'BO',
  'CL',
  'CO',
  'CR',
  'CU',
  'DO',
  'EC',
  'SV',
  'GT',
  'HN',
  'MX',
  'NI',
  'PA',
  'PY',
  'PE',
  'PR',
  'ES',
  'UY',
  'VE',
  'GQ'
]

export default function () {
  const user_language = navigator.language || navigator.languages[0]
  let locale = 'en'
  let url = window.location
  let url_first_segment = url.pathname.split('/')[1]
  let { is_web } = get_device_environment()

  if (is_web) {
    if (['en'].includes(url_first_segment)) {
      return 'en'
    }
  }

  if (user_language.startsWith('es')) {
    locale = 'es'
  } else {
    const region = Intl.DateTimeFormat().resolvedOptions().locale.split('-')[1]

    if (spanish_speaking_countries.includes(region)) {
      locale = 'es'
    } else {
      locale = 'en'
    }
  }

  return locale
}

<template>
  <div v-if="post_style === 'full'" class="post-component full">
    <div
      v-if="image_11"
      class="post-image"
      :style="{ 'background-image': `url(${image_11})` }"
    ></div>
    <div class="post-wrapper">
      <h3 class="post-title">
        <a :href="link" :title="post.title" class="post-link">
          {{ post.title }}
        </a>
      </h3>
      <p class="description">
        {{ post.description }}
      </p>
      <div class="post-block-of-info">
        <div class="post-badge" v-if="stats.total_views > 0">
          <i class="fi fi-rr-eye"></i>
          {{ stats.total_views }}
        </div>

        <div class="post-badge" v-if="stats.total_likes > 0">
          <i class="fi fi-rr-social-network"></i>
          {{ stats.total_likes }}
        </div>

        <div v-if="created_by" class="post-badge">
          <i class="fi fi-rr-user"></i>
          <div class="flex gap-1">
            Por:
            {{ created_by.name }}
          </div>
        </div>
      </div>
      <p class="updated_at">
        {{ post.updated_at['relative'] }}
      </p>
    </div>
  </div>

  <div v-if="post_style === 'condensed'" class="post-component condensed">
    <div class="post-wrapper">
      <h3 class="post-title">
        <a
          :href="link"
          :title="post.title"
          class="text-decoration-none text-xl text-slate-800"
        >
          {{ post.title }}
        </a>
      </h3>
      <p class="description">
        {{ post.description }}
      </p>
      <div class="post-block-of-info">
        <div class="post-badge" v-if="stats.total_views > 0">
          <i class="fi fi-rr-eye"></i>
          {{ stats.total_views }}
        </div>

        <div class="post-badge" v-if="stats.total_likes > 0">
          <i class="fi fi-rr-social-network"></i>
          {{ stats.total_likes }}
        </div>

        <div v-if="created_by" class="post-badge">
          <i class="fi fi-rr-user"></i>
          <div class="flex gap-1">
            Por:
            {{ created_by.name }}
          </div>
        </div>
      </div>
      <p class="updated_at">
        {{ post.updated_at['relative'] }}
      </p>
    </div>
  </div>
</template>

<script setup>
  // Helpers
  import url from '@/helpers/url'

  // ENV Enums
  import ENV from '@/../env.json'

  // Libs
  import { toRefs, computed } from 'vue'

  // Props
  const props = defineProps({
    post_style: {
      default: 'full'
    },
    post: {
      default: {}
    }
  })

  // Variables
  // Destructure props to reactive references
  const { post, post_style } = toRefs(props)

  // Computed variables
  const created_by = computed(() => {
    return post.value.created_by?.name ? post.value.created_by : null
  })

  const stats = computed(() => {
    return post.value.stats ? post.value.stats : null
  })

  const link = computed(() => {
    return url({
      origin: 'memeonlyme.com',
      path: `/post/${post.value.slug}`
    })
  })

  const image_11 = computed(() => {
    let image = post.value.images.find(
      (x) => x.tag === ENV.enums.FileTags.POST_COVER_11
    )

    if (
      image &&
      image.path.length > 0 &&
      /\.(jpg|jpeg|png|gif|webp)$/i.test(image.path)
    ) {
      return url({
        origin: 'memeonlyme.com',
        path: image.path,
        with_locale: false
      })
    }

    return null
  })
</script>

// Styles
import 'animate.css'

// Libs
import { createApp } from 'vue'
import { register as registerSwiper } from 'swiper/element/bundle'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'

// import Echo from 'laravel-echo'
// import Pusher from 'pusher-js'
// import ENV from '@/../env.json'

//
registerSwiper()

// Setup Echo
// window.Pusher = Pusher
// window.Echo = new Echo({
//   broadcaster: ENV.reverb.broadcaster,
//   key: ENV.reverb.key,
//   wsHost: ENV.reverb.wsHost,
//   wsPort: ENV.reverb.wsPort,
//   wssPort: ENV.reverb.wssPort,
//   forceTLS: ENV.reverb.forceTLS,
//   enabledTransports: ENV.reverb.enabledTransports
// })

// Helpers
import get_and_store_utm_variables from '@/helpers/get_and_store_utm_variables'
import get_and_store_user_session from '@/helpers/get_and_store_user_session'

get_and_store_utm_variables()
get_and_store_user_session()

// Import translations
import en_translations from '@/locales/en.json'
import es_translations from '@/locales/es.json'

// App components
import route_button_likes from '@/components/app/routes/buttons/likes.vue'
import post_button_likes from '@/components/app/posts/buttons/likes.vue'
import post_button_play from '@/components/app/posts/buttons/play.vue'

// Common components
import shortener_component from '@/components/commons/shortener.vue'

// Template components
import header_component from '@/components/template/web/header.vue'

// Routes
// Components
import routes_form_with_route_filtering from '@/pages/routes/components/form_with_route_filtering.vue'

// Routes
// Components
import route_units from '@/pages/route/components/units.vue'

// Blog
// Components
import blogs_form_with_posts_filtering from '@/pages/blog/components/blogs_form_with_posts_filtering.vue'

// Register
// Components
import register_form_to_register from '@/pages/register/components/form_to_register.vue'

// Notification
// Components
import notifications_form_to_remove_notifications from '@/pages/notifications/components/form_to_remove_notifications.vue'

// Bye
// Components
import bye_form_to_delete_account from '@/pages/bye/components/form_to_delete_account.vue'

// Login
// Components
import login_form_to_login from '@/pages/login/components/form_to_login.vue'

// Create i18n instance
const i18n = createI18n({
  legacy: false,
  locale: localStorage.getItem('locale') || 'es',
  fallbackLocale: 'en',
  messages: {
    en: en_translations,
    es: es_translations
  }
})

const app = createApp({})
const pinia = createPinia()

app.use(pinia)
app.use(i18n)

app.component('shortener_component', shortener_component)

// Template
app.component('header_component', header_component)

// Routes
// Components
app.component(
  'routes_form_with_route_filtering',
  routes_form_with_route_filtering
)

// Route
// Components
app.component('route_units', route_units)
app.component('route_button_likes', route_button_likes)

// Blog
// Components
app.component(
  'blogs_form_with_posts_filtering',
  blogs_form_with_posts_filtering
)
app.component('post_button_likes', post_button_likes)
app.component('post_button_play', post_button_play)

// Register
// Components
app.component('register_form_to_register', register_form_to_register)

// Login
// Components
app.component('login_form_to_login', login_form_to_login)

// Notification
// Components
app.component(
  'notifications_form_to_remove_notifications',
  notifications_form_to_remove_notifications
)

// Bye
// Components
app.component('bye_form_to_delete_account', bye_form_to_delete_account)

// Stores
// Restore user session
import UserStore from '@/stores/user'
UserStore().restore()

app.mount('#app')

// FIX ME - Se supone que esto seria para manejar las notificaciones en real backend-frontend
// Listening event
// window.Echo.channel('public.notifications').listen('.server.time', (data) => {
//   console.log({ data })
// })

// Service
import BaseService from '@/services/base'

export default class extends BaseService {
  constructor(args = {}) {
    super(args)
  }

  store = async (body = {}) => {
    return await this.execute({
      name: 'track.store',

      method: 'post',
      target: '/v1/track',
      body,

      schema: {
        success: {
          required: ['track'],
          properties: {
            track: { type: 'object' }
          }
        }
      }
    })
  }
}

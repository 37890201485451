import { defineStore } from 'pinia'
import { reactive, computed } from 'vue'

export default defineStore('user', () => {
  // Reactive user object
  let user = reactive({})

  // Computed property to check if user is logged in
  const is_logged = computed(() => {
    return !!user?.token
  })

  // Update user information and save to localStorage
  function update(new_user) {
    localStorage.setItem('user', JSON.stringify(new_user))
    Object.assign(user, new_user || {})
  }

  // Restore user information from localStorage
  function restore() {
    const stored_user = JSON.parse(localStorage.getItem('user') || '{}') || {}

    Object.assign(user, stored_user)

    // Check if token has expired and clear user if so
    if (user?.token_expires_at) {
      const token_expires_at = new Date(user.token_expires_at)

      if (new Date() > token_expires_at) {
        Object.assign(user, {})
      }
    }
  }

  // Clear user information and remove from localStorage
  function logout() {
    localStorage.removeItem('user')
    Object.keys(user).forEach((key) => {
      delete user[key]
    })
  }

  // Return reactive user state and functions
  return {
    user,
    is_logged,
    update,
    restore,
    logout
  }
})

<template>
  <div class="container !max-w-screen-md">
    <div class="flex flex-col gap-4 w-full">
      <div class="flex flex-col gap-2">
        <input_component
          :settings="input_search"
          @update:value="
            ($event) => {
              search = $event
              get_records()
            }
          "
        />

        <transition name="fade">
          <div class="flex flex-wrap gap-2" v-if="symptoms.length > 0">
            <span
              class="badge badge-light cursor-pointer"
              v-for="symptom in symptoms.filter((x) => x.title !== search)"
              :key="symptom.id"
              @click="search = symptom.title"
            >
              {{ symptom.title }}
            </span>
          </div>
        </transition>
      </div>

      <div class="flex flex-col gap-2" v-if="not_results_found">
        <alert_component>
          <template #message>
            {{ gt('no_results.message') }}
            <a :href="url({ path: '/suggestions' })">{{
              gt('no_results.button.text')
            }}</a>
          </template>
        </alert_component>

        <div class="buttons flex flex-wrap gap-2">
          <a
            :class="['btn', 'btn-light', '!w-fit', '!grow-0']"
            href="/suggestions"
            :title="gt('no_results.button.title')"
          >
            <i class="fi fi-rr-add-document"></i>
            {{ gt('no_results.button.text') }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <routes_component
    default_style="swiper"
    :show_switcher="true"
    :routes="routes"
    :max_elements="max_elements"
    :on_click="redirect_to_route_page"
  />

  <posts_component :posts="posts" :loading="search_posts_loading" />
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Helpers
  import scroll_to_element from '@/helpers/scroll_to_element'
  import url from '@/helpers/url'
  import get_locale from '@/helpers/get_locale'

  // Components
  import routes_component from '@/components/app/routes/index.vue'
  import posts_component from '@/components/app/posts/index.vue'

  // Libs
  import { onMounted, ref, computed } from 'vue'
  import { debounce } from 'lodash-es'
  import Route from '@/methods/for_routes'
  import Post from '@/methods/for_posts'
  import Symptom from '@/methods/for_symptoms'
  import { useI18n } from 'vue-i18n'

  // Variables
  const max_elements = ref(7)

  const { t } = useI18n()
  const gt = (key) => t(`laravel.vue.pages.routes.components.filtering.${key}`)

  const search = ref('')
  const search_posts_loading = ref(true)
  const search_symptoms_loading = ref(true)
  const input_search = ref({
    label: gt('input_search.label'),
    placeholder: gt('input_search.placeholder'),
    name: 'search',
    type: 'text',
    default: search.value,
    icon: {
      name: 'fi-rr-search'
    }
  })

  const routes = ref([])
  const posts = ref([])
  const symptoms = ref([])

  // Computed variables
  const not_results_found = computed(() => {
    return routes.value.length + posts.value.length === 0
  })

  // Methods
  const get_routes_by_search = debounce(async function (args = {}) {
    args.per_page = args.per_page || max_elements.value
    args.with_relations = ['createdBy']
    args.with_properties = ['images', 'stats']
    args.filters = {
      search: search.value,
      symptoms: search.value,
      tags: search.value,
      diffuse: 1,
      language: get_locale()
    }

    const { records } = await Route.get(args)
    routes.value = records || []

    if (search.value.length > 0 && routes.value.length > 0) {
      scroll_to_element('.component-routes', 500)
    }
  }, 700)

  const get_posts_by_search = debounce(async function (args = {}) {
    args.per_page = args.per_page || 5
    args.with_relations = ['createdBy']
    args.with_properties = ['images', 'stats']
    args.filters = {
      search: search.value,
      diffuse: 1,
      language: get_locale()
    }

    const { records } = await Post.get(args)
    posts.value = records || []
    search_posts_loading.value = false
  }, 700)

  const get_symptoms_by_search = debounce(async function (args = {}) {
    search_symptoms_loading.value = true
    args.per_page = args.per_page || 7
    args.with_relations = []
    args.with_properties = []

    args.filters = {
      search: search.value,
      symptoms_with_routes: 1,
      diffuse: 1,
      language: get_locale()
    }

    const { records } = await Symptom.get(args)
    symptoms.value = records || []
    search_symptoms_loading.value = false
  }, 700)

  const get_params_and_use_it = async function () {
    const params = new URLSearchParams(window.location.search)
    const keyword = params.get('keyword', null)

    if (keyword && keyword.length > 0) {
      search.value = keyword
    }
  }

  const get_records = async function () {
    await get_params_and_use_it()
    await get_routes_by_search()
    await get_posts_by_search()
    await get_symptoms_by_search()
  }

  const redirect_to_route_page = function (route) {
    window.location.href = url({ path: `/route/${route.slug}` })
  }

  // Lifecycle
  onMounted(async () => {
    await get_records()
  })
</script>

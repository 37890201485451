// ENV Variables
import ENV from '../../env.json'

// Libs
import { defineStore } from 'pinia'
import { reactive, ref } from 'vue'

// Define the store for handling error notifications
export default defineStore('app', () => {
  const alert = reactive({
    active: false,
    level: ENV.enums.AlertLevels.DARK,
    close_after: null,
    title: '',
    message: ''
  })

  const popup = reactive({
    active: false,
    level: ENV.enums.AlertLevels.DARK,
    close_after: null,
    message: ''
  })

  // Method to manage notifications (alerts and popups)
  function notification(notification) {
    const close_after = notification.close_after || 5
    const type = notification.type || 'popup'
    const state = type === 'alert' ? alert : popup

    Object.assign(state, {
      ...state,
      ...notification,
      active: true
    })

    if (close_after > 0) {
      setTimeout(() => {
        state.active = false
      }, close_after * 1000)
    }
  }

  // Method to hide the popup notification
  function hide_popup() {
    Object.assign(popup, {
      ...popup,
      active: false
    })
  }

  // Method to hide the alert notification
  function hide_alert() {
    Object.assign(alert, {
      ...alert,
      active: false
    })
  }

  // Return the state and methods to be used by the store
  return {
    alert,
    popup,
    notification,
    hide_popup,
    hide_alert
  }
})

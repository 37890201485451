// ENV Variables
import ENV from '@/../env.json'

// Stores
import AppStore from '@/stores/app'

/**
 * Delete a resource.
 */
export default async function (Service, args = {}) {
  // Reusable Instances
  const App = AppStore()
  const silent = args.silent || false

  args.id = args.id || null
  args.route_id = args.route_id || null
  args.unit_id = args.unit_id || null
  args.property_id = args.property_id || null
  args.file_id = args.file_id || null

  if (!confirm('Seguro que quieres continuar?')) {
    return {
      success: false,
      message: 'Operación cancelada.'
    }
  }

  const response = await Service(args)

  const { success, message } = response

  if (!silent && message) {
    App.notification({
      message,
      level: success
        ? ENV.enums.AlertLevels.SUCCESS
        : ENV.enums.AlertLevels.DANGER
    })
  }

  return response
}

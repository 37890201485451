// Wrappers
import StoreWrapper from './helpers/wrappers/store'
import GetWrapper from './helpers/wrappers/get'
import DeleteWrapper from './helpers/wrappers/delete'

// Services
import UnitService from '@/services/unit'

export default {
  /**
   * Fetches a list of resources.
   */
  async get(args = {}) {
    const { success, units, pagination } = await GetWrapper(
      new UnitService().get,
      args
    )

    return {
      success,
      records: units || [],
      pagination
    }
  },

  /**
   * Stores a new resource.
   */
  async store({ $form, append = {} }) {
    return await StoreWrapper(new UnitService().store, {
      $form,
      append
    })
  },

  /**
   * Deletes a resource.
   */
  async delete({ id }) {
    const { success, message } = await DeleteWrapper(new UnitService().delete, {
      id,
      append: { force: true }
    })

    return {
      success,
      message
    }
  },

  /**
   * Update a resource.
   */
  async update_progress({ unit_id, progress, silent = true }) {
    const { success, message } = await StoreWrapper(
      new UnitService().update_progress,
      {
        append: {
          unit_id,
          progress
        },

        silent
      }
    )

    return {
      success,
      message
    }
  }
}

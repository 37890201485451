<template>
  <form_notifications_component
    :user_id="user_id"
    :on_response="on_response"
  ></form_notifications_component>
</template>
<script setup>
  // Components
  import form_notifications_component from '@/components/commons/forms/notifications.vue'

  // Libs
  import { toRefs } from 'vue'

  const props = defineProps({
    user_id: {
      default: null
    }
  })

  // Destructure props to reactive references
  const { user_id } = toRefs(props)

  // Methods
  const on_response = ({ success }) => {}
</script>

<template>
  <div
    v-if="route_style === 'vertical'"
    class="component-route vertical"
    :style="{ 'background-image': `url(${image_19})` }"
    @click="on_click(route)"
  >
    <div class="overlay">
      <div class="block">
        <h3 class="title">
          {{ title }}
        </h3>

        <p class="created_by" v-if="created_by">
          {{ created_by.name }}
        </p>

        <div class="stats" v-if="stats || suggested">
          <div class="stats-item" v-if="suggested === true">
            <i class="fi fi-rr-sparkles text-cyan-500"></i>
          </div>

          <div class="stats-item" v-if="stats && stats.total_views > 0">
            <i class="fi fi-rr-eye"></i>
            {{ stats.total_views }}
          </div>

          <div class="stats-item" v-if="stats && stats.total_likes > 0">
            <i class="fi fi-rr-social-network"></i>
            {{ stats.total_likes }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    v-if="route_style === 'card'"
    class="component-route"
    @click="on_click(route)"
  >
    <div class="card-style">
      <div class="flex flex-col gap-1">
        <h3 class="title">{{ title }}</h3>

        <p class="description">
          {{ description }}
        </p>
      </div>

      <div class="block-footer">
        <div class="flex gap-2 flex-wrap">
          <div
            v-if="created_by"
            class="badge badge-light gap-2"
            style="white-space: nowrap"
          >
            <i class="fi fi-rr-user leading-[0px]"></i>
            <div class="flex gap-1">
              Por:
              {{ created_by.name }}
            </div>
          </div>

          <div class="flex flex-wrap gap-2">
            <div class="badge badge-light" v-if="suggested === true">
              <i class="fi fi-rr-sparkles"></i>
            </div>

            <div class="badge badge-light">
              <i class="fi fi-rr-eye"></i>
              {{ stats.total_views }}
            </div>

            <div class="badge badge-light">
              <i class="fi fi-rr-social-network"></i>
              {{ stats.total_likes }}
            </div>
          </div>
        </div>

        <div class="flex gap-2 items-center">
          <!--  -->
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // Helpers
  import url from '@/helpers/url'
  import get_words from '@/helpers/get_words'

  // Enums
  import ENV from '@/../env.json'

  // Libs
  import { toRefs, computed } from 'vue'

  // Props
  const props = defineProps({
    route_style: {
      default: 'vertical'
    },

    route: {
      default: {}
    },

    on_click: {
      default: () => {}
    }
  })

  // Variables
  // Destructure props to reactive references
  const { route, on_click, route_style } = toRefs(props)

  // Computed variables
  const created_by = computed(() => {
    return route.value.created_by?.name ? route.value.created_by : null
  })

  const stats = computed(() => {
    return route.value.stats ? route.value.stats : null
  })

  const description = computed(() => {
    return get_words(route.value.description || '', 36)
  })

  const title = computed(() => {
    return route.value.title
  })

  const suggested = computed(() => {
    return route.value?.suggested
  })

  const image_19 = computed(() => {
    let image = route.value.images.find(
      (x) => x.tag === ENV.enums.FileTags.ROUTE_COVER_19
    )

    if (image) {
      return url({
        origin: 'memeonlyme.com',
        path: image.path,
        with_locale: false
      })
    }

    return ''
  })
</script>

<template>
  <div class="container !max-w-screen-md">
    <div class="flex flex-col gap-2 w-full">
      <div class="flex flex-col gap-2">
        <input_component
          :settings="{
            ...input_search,
            label: gt('input_search_label'),
            placeholder: gt('input_search_placeholder')
          }"
          @update:value="
            ($event) => {
              search = $event
              debounce_get_posts_by_search()
              debounce_get_symptoms_by_search()
            }
          "
        />

        <div class="flex flex-wrap gap-2" v-if="symptoms.length > 0">
          <span
            class="badge badge-light cursor-pointer"
            v-for="symptom in symptoms.filter((x) => x.title !== search)"
            :key="symptom.id"
            @click="search = symptom.title"
          >
            {{ symptom.title }}
          </span>
        </div>
      </div>

      <div class="flex flex-col gap-2" v-if="not_results_found">
        <alert_component>
          <template #message>
            {{ gt('not_results_message') }}
            <a :href="url({ path: '/suggestions' })">clic aquí</a>
          </template>
        </alert_component>

        <div class="buttons flex flex-wrap gap-2">
          <a
            :class="['btn', 'btn-light', '!w-fit', '!grow-0']"
            href="/suggestions"
            :title="gt('button_suggestion_title')"
          >
            <i class="fi fi-rr-add-document"></i>
            {{ gt('button_suggestion_text') }}
          </a>
        </div>
      </div>
    </div>
  </div>

  <posts_component
    :posts="posts"
    :with_pagination="true"
    :posts_pagination="posts_pagination"
    :on_go_to_page="get_posts_by_search"
  />
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const posts_component = defineAsyncComponent(
    () => import('@/components/app/posts/index.vue')
  )

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )
  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Helpers
  import scroll_to_element from '@/helpers/scroll_to_element'
  import url from '@/helpers/url'
  import get_locale from '@/helpers/get_locale'

  // Libs
  import { onMounted, ref, computed } from 'vue'
  import { debounce } from 'lodash-es'
  import Post from '@/methods/for_posts'
  import Symptom from '@/methods/for_symptoms'
  import { useI18n } from 'vue-i18n'

  // Variables
  const { t } = useI18n()
  const gt = (key) => t(`laravel.vue.pages.blog.components.blogs_form.${key}`)

  const search = ref('')
  const posts = ref([])
  const posts_pagination = ref({})
  const symptoms = ref([])
  const input_search = ref({
    label: gt('input_search_label'),
    placeholder: gt('input_search_placeholder'),
    name: 'search',
    type: 'text',
    default: search.value,
    icon: {
      name: 'fi-rr-search'
    }
  })

  // Computed variables
  const not_results_found = computed(() => {
    return posts.value.length === 0
  })

  // Métodos
  const get_posts_by_search = async function (args = {}) {
    args.per_page = args.per_page || 5
    args.with_relations = ['createdBy']
    args.with_properties = ['images', 'stats']
    args.filters = {
      search: search.value,
      diffuse: 1,
      language: get_locale()
    }

    const { records, pagination } = await Post.get(args)

    posts.value = records || []
    posts_pagination.value = pagination || {}
  }

  const get_symptoms_by_search = async function (args = {}) {
    args.per_page = args.per_page || 7
    args.filters = {
      search: search.value,
      random: 1,
      diffuse: 1,
      language: get_locale()
    }

    const { records } = await Symptom.get(args)
    symptoms.value = records || []

    if (search.value.length > 0 && symptoms.value.length > 0) {
      scroll_to_element('.component-posts', 500)
    }
  }

  const get_records = async function () {
    await get_posts_by_search()
    await get_symptoms_by_search()
  }

  const debounce_get_posts_by_search = debounce(get_posts_by_search, 700)
  const debounce_get_symptoms_by_search = debounce(get_symptoms_by_search, 700)

  // Lifecycle
  onMounted(() => {
    get_records()
  })
</script>

import { v4 as uuidv4 } from 'uuid'

function create_session() {
  const session_id = uuidv4()
  const expire_time = Date.now() + 5 * 60 * 1000

  localStorage.setItem('session_id', session_id)
  localStorage.setItem('session_expire_at', expire_time.toString())
}

export default () => {
  const session_id = localStorage.getItem('session_id')
  const session_expire_at = localStorage.getItem('session_expire_at')

  if (
    !session_id ||
    !session_expire_at ||
    Date.now() > parseInt(session_expire_at, 10)
  ) {
    localStorage.removeItem('session_id')
    localStorage.removeItem('session_expire_at')
    create_session()
  }
}

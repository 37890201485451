<template>
  <div class="component-unit card-style relative" @click="open(unit)">
    <div v-if="locked" class="absolute right-4 top-4">
      <i class="fi fi-rr-lock leading-[0px] text-xl"></i>
    </div>

    <div class="flex flex-col gap-1">
      <h3 class="title">{{ title }}</h3>

      <p class="description">
        {{ description }}
      </p>
    </div>

    <div class="block-footer">
      <div class="flex gap-2 flex-wrap">
        <div
          v-if="created_by"
          class="badge badge-light gap-2"
          style="white-space: nowrap"
        >
          <i class="fi fi-rr-user leading-[0px]"></i>
          <div class="flex gap-1">
            Por:
            {{ created_by.name }}
          </div>
        </div>
      </div>

      <div class="flex gap-2 items-center">
        <!--  -->
      </div>
    </div>

    <div class="flex w-full" v-if="progress > 0">
      <div class="progress">
        <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
  // ENV Variables
  import ENV from '@/../env.json'

  // Libs
  import { toRefs, computed, ref } from 'vue'
  import Unit from '@/methods/for_units'
  import Track from '@/methods/for_tracks'

  // Helpers
  import get_words from '@/helpers/get_words'

  // Stores
  import UserStore from '@/stores/user'
  import PlayerStore from '@/stores/player'
  import AppStore from '@/stores/app'

  // Define props
  const props = defineProps({
    style: {
      default: 'card'
    },

    locked: {
      default: true
    },

    unit: {
      default: {}
    },

    player_on_finish: {
      default: () => {}
    }
  })

  // Variables
  const { unit, locked, player_on_finish } = toRefs(props)
  const User = UserStore()
  const App = AppStore()
  const Player = PlayerStore()
  const update_on_progress = ref(1)

  // Computed vars
  const description = computed(() => {
    return get_words(unit.value.description || '', 36)
  })

  const title = computed(() => {
    return unit.value.title
  })

  const created_by = computed(() => {
    return unit.value.created_by || null
  })

  const progress = computed(() => {
    return unit.value?.status?.progress || 0
  })

  // Methods
  const open = (unit) => {
    if (!User.is_logged) {
      App.notification({
        title: 'Inicia sesión',
        message: 'Para acceder a esta unidad, inicia sesión.',
        level: ENV.enums.AlertLevels.DANGER
      })
    } else if (unit.locked_by_progress) {
      App.notification({
        title: 'Vamos por partes',
        message: 'Finaliza primero la ruta anterior para poder continuar.',
        level: ENV.enums.AlertLevels.DANGER
      })
    } else if (locked.value === true) {
      App.notification({
        title: 'Emmm...',
        message: 'Al parecer esta ruta esta bloqueada.',
        level: ENV.enums.AlertLevels.DANGER
      })
    } else {
      Player.add_resource({
        type: 'unit',
        ...unit
      })

      Player.update_callback('on_playing', ({ progress }) => {
        if (progress > update_on_progress.value) {
          update_on_progress.value += 5
          Unit.update_progress({
            unit_id: unit.id,
            progress
          })
        }
      })

      Player.update_callback('on_finish', () => {
        Unit.update_progress({
          unit_id: unit.id,
          progress: 100,
          silent: false
        })

        player_on_finish.value()
        Track.store({
          event: 'unit_finished',
          unit_id: unit.id,
          route_id: unit.route_id,
          update: true
        })
      })
    }
  }
</script>

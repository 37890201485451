<template>
  <span :class="['flex', 'items-center', 'gap-1', ...classes]">
    <i
      v-if="with_copy"
      class="cursor-pointer fi fi-rr-copy-alt"
      @click="on_click()"
    ></i>

    <span>{{ is_expanded ? content : shortened_content }}</span>

    <span
      v-if="with_expand && !is_expanded && content.length > max_length"
      class="cursor-pointer btn btn-light btn-condensed"
      @click="expand"
    >
      <i class="fi fi-rr-arrow-down"></i>
      Leer mas
    </span>
  </span>
</template>

<script setup>
  // Libs
  import { computed, ref, toRefs } from 'vue'

  // Helpers
  import copy_to_clipboard from '@/helpers/copy_to_clipboard'

  // Stores
  import AppStore from '@/stores/app'

  const props = defineProps({
    content: {
      default: ''
    },

    max_length: {
      default: 10
    },

    classes: {
      default: []
    },

    with_copy: {
      default: true
    },

    with_expand: {
      default: false
    }
  })

  const { content, max_length, with_copy, with_expand, classes } = toRefs(props)

  const is_expanded = ref(false)

  const shortened_content = computed(() => {
    if (!content.value) return ''
    return content.value.length > max_length.value
      ? content.value.slice(0, max_length.value) + '...'
      : content.value
  })

  // Methods
  const on_click = async function () {
    const success = await copy_to_clipboard(content.value)

    if (success) {
      AppStore().notification({
        title: 'Hey!',
        message: 'Copiado en tu portapapeles.',
        close_after: 3
      })
    }
  }

  const expand = function () {
    is_expanded.value = true
  }
</script>

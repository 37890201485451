<template>
  <alert_component :level="ENV.enums.AlertLevels.DARK">
    <template #title> ¿Sabias que... puedes ver esta ruta gratis? </template>
    <template #message>
      <div class="block">
        Desbloquea esta ruta y sus unidades por unos días haciendo
        <a :href="page_to_unlock" title="Desbloquea las unidades">
          click aquí
        </a>
        y completando tus datos!
      </div>
    </template>
  </alert_component>
</template>
<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // Helpers
  import url from '@/helpers/url'

  // ENV Variables
  import ENV from '@/../env.json'

  // Variables
  const page_pathname = window.location.pathname
  const page_to_unlock = url({
    path: '/unlock',
    params: { redirect_to: page_pathname }
  })
</script>

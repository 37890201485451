// Serializations
import routes_serializer from '@/serializers/routes'

// Service
import BaseService from '@/services/base'

export default class extends BaseService {
  constructor(args = {}) {
    super(args)
  }

  get = async (body = {}) => {
    return await this.execute({
      name: 'routes.get',

      method: 'get',
      target: '/v1/routes',
      serializers: {
        routes: routes_serializer
      },
      body,

      schema: {
        success: {
          required: ['routes', 'pagination'],
          properties: {
            routes: { type: 'array' },
            pagination: { type: 'object' }
          }
        }
      }
    })
  }

  delete = async (body = {}) => {
    return await this.execute({
      name: 'route.delete',

      method: 'delete',
      target: '/v1/route',
      body
    })
  }

  store = async (body = {}) => {
    return await this.execute({
      name: 'routes.store',

      method: 'post',
      target: '/v1/route',
      body
    })
  }

  get_properties = async (body = {}) => {
    return await this.execute({
      name: 'routes.get_properties',

      method: 'get',
      target: '/v1/route/properties',
      body,

      schema: {
        success: {
          required: ['properties'],
          properties: {
            properties: { type: 'array' }
          }
        }
      }
    })
  }

  get_symptoms = async (body = {}) => {
    return await this.execute({
      name: 'routes.get',

      method: 'get',
      target: '/v1/route/symptoms',
      body,

      schema: {
        success: {
          required: ['symptoms'],
          properties: {
            symptoms: { type: 'array' }
          }
        }
      }
    })
  }

  get_status = async (body = {}) => {
    return await this.execute({
      name: 'routes.get_status',

      method: 'get',
      target: '/v1/route/status',
      body
    })
  }

  get_stats = async (body = {}) => {
    return await this.execute({
      name: 'route.get_stats',

      method: 'get',
      target: '/v1/route/stats',
      body,

      schema: {
        success: {
          required: ['route', 'liked_by_me'],
          properties: {
            route: { type: 'object' },
            liked_by_me: { type: 'boolean' }
          }
        }
      }
    })
  }

  update_like = async (body = {}) => {
    return await this.execute({
      name: 'routes.update_like',

      method: 'post',
      target: '/v1/route/update/like',
      body
    })
  }

  assign_symptom = async (body = {}) => {
    return await this.execute({
      name: 'route.assign_symptom',

      method: 'post',
      target: '/v1/route/symptom/assign',
      body
    })
  }

  unassign_symptom = async (body = {}) => {
    return await this.execute({
      name: 'route.unassign_symptom',

      method: 'delete',
      target: '/v1/route/symptom/unassign',
      body
    })
  }
}

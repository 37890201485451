// ENV Variables
import ENV from '@/../env.json'

// Helpers
import get_locale from '@/helpers/get_locale'

export default function ({
  origin = 'memeonlyme.com',
  path = '/',
  params = {},
  with_locale = true
}) {
  const locale = get_locale()

  if (locale !== 'es' && with_locale) {
    path = `/${locale}` + path
  }

  let url = /^(https?:\/\/)/i.test(path)
    ? path
    : new URL(path, ENV.sources[origin]).toString()

  const query = new URLSearchParams(params).toString()
  const query_connector = url.includes('?') ? '&' : '?'

  return query ? url + query_connector + query : url
}

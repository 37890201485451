<template>
  <form :class="[...(!clean ? ['card', 'p-4'] : [])]" @submit.prevent="login()">
    <div class="w-full flex flex-col gap-2">
      <input_component
        :settings="form.inputs.email"
        @update:value="form.inputs.email.value = $event"
      />

      <input_component
        :settings="form.inputs.password"
        @update:value="form.inputs.password.value = $event"
      />

      <div class="flex justify-between content-between w-100">
        <input_component
          :settings="form.inputs.remember_me"
          @update:value="form.inputs.remember_me.value = $event"
        />

        <div class="flex items-center gap-2">
          <a class="font-medium" href="#">
            {{ gt('recover_password') }}
          </a>
        </div>
      </div>

      <div v-if="form.error" class="alert alert-light">
        <i class="fi fi-rr-info"></i> {{ form.error }}
      </div>

      <button_component
        :classes="['btn', 'btn-dark', 'grow']"
        :loading="form.is_loading"
        :submittable="true"
      >
        <i class="fi fi-rr-sign-in-alt"></i>
        {{ gt('login_button') }}
      </button_component>

      <p class="block text-center text-slate-500">
        {{ gt('login_with_other_method') }}
      </p>

      <div class="buttons flex gap-2">
        <button_component
          :classes="['btn', 'btn-google', 'w-full']"
          @click="login('google')"
          :loading="login_with_google_is_loading"
        >
          <i class="fi fi-brands-google"></i>
          {{ gt('login_with_google') }}
        </button_component>

        <button_component
          :classes="['btn', 'btn-dark', 'w-full']"
          @click="login('apple')"
          :loading="login_with_apple_is_loading"
          v-if="show_apple_login"
        >
          <i class="fi fi-brands-apple"></i>
          {{ gt('login_with_apple') }}
        </button_component>
      </div>

      <div class="flex flex-col">
        <p class="text-center text-slate-500">
          {{ gt('accept_terms_conditions') }}
          <a
            :href="url({ path: '/terms' })"
            target="_blank"
            class="text-slate-800"
          >
            {{ gt('terms_conditions_link') }} </a
          >,
          <a
            :href="url({ path: '/privacy' })"
            target="_blank"
            class="text-slate-800"
          >
            {{ gt('privacy_policy_link') }}
          </a>
          {{ gt('cookies_policy_link') }}
        </p>
      </div>
    </div>
  </form>

  <div class="flex flex-col" v-if="with_sign_up">
    <p class="text-slate-500 text-center">
      {{ gt('no_account_question') }}
      <a title="Regístrate" :href="url({ path: '/register' })">
        {{ gt('register_link') }}
      </a>
    </p>
  </div>
</template>

<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'

  const button_component = defineAsyncComponent(
    () => import('@/components/commons/button.vue')
  )

  const input_component = defineAsyncComponent(
    () => import('@/components/commons/input.vue')
  )

  // Stores
  import UserStore from '@/stores/user'

  // Helpers
  import url from '@/helpers/url'
  import get_device_environment from '@/helpers/get_device_environment'
  import get_form_state from '@/helpers/get_form_state'
  import login_with from './methods/commons/login_with'
  import { useI18n } from 'vue-i18n'

  // Libs
  import { toRefs, ref, computed, onMounted } from 'vue'
  import Authentication from '@/methods/for_authentication'

  // Stores
  import AppStore from '@/stores/app'

  // Props
  const props = defineProps({
    with_sign_up: {
      type: Boolean,
      default: true
    },

    clean: {
      type: Boolean,
      default: false
    },

    on_response: {
      default: null
    }
  })

  // Variables
  const { t } = useI18n()
  const gt = (key, args = {}) => t(`components.forms.login.${key}`, args)

  const { on_response } = toRefs(props)
  const App = AppStore()
  const login_with_google_is_loading = ref(false)
  const login_with_apple_is_loading = ref(false)
  const form = get_form_state('login', [
    {
      label: gt('email_label'),
      name: 'email',
      type: 'text'
    },
    {
      label: gt('password_label'),
      name: 'password',
      type: 'password',
      icon: {
        name: 'fi-rr-eye',
        onclick: {
          callback: () => {
            form.inputs.password.type =
              form.inputs.password.type === 'password' ? 'text' : 'password'
          }
        }
      }
    },
    {
      label: gt('remember_me_label'),
      name: 'remember_me',
      type: 'checkbox',
      value: true
    },
    {
      label: gt('authorization_label'),
      name: 'authorization',
      type: 'hidden'
    },
    {
      label: gt('user_id_label'),
      name: 'user_id',
      type: 'hidden'
    }
  ])

  const show_apple_login = computed(() => {
    const { os } = get_device_environment()
    return os === 'ios' || os === 'macos'
  })

  const login = async (provider = null) => {
    let response = null
    let name = null
    let email = null
    let google_id = null
    let apple_id = null
    let photo = null

    form.is_loading = true

    if (provider === 'google') {
      login_with_google_is_loading.value = true
      response = await login_with('google')
    }

    if (provider === 'apple') {
      login_with_apple_is_loading.value = true
      response = await login_with('apple')
    }

    if (provider) {
      if (!response) {
        form.is_loading = false
        login_with_google_is_loading.value = false
        login_with_apple_is_loading.value = false
        return
      }

      name = response.name
      email = response.email
      photo = response.photo

      if (provider === 'google') {
        google_id = response.uid
      }

      if (provider === 'apple') {
        apple_id = response.uid
      }
    }

    const { success, errors, message, user } =
      await Authentication.authenticate({
        $form: form,
        append: {
          name,
          email,
          google_id,
          apple_id,
          photo,
          logged_by: provider
        }
      })

    if (success && user) {
      UserStore().update(user)
    }

    if (on_response.value) {
      on_response.value({ success, errors, message, user })
    }

    form.is_loading = false
    login_with_google_is_loading.value = false
    login_with_apple_is_loading.value = false
  }

  const auth_by_token = function () {
    const params = new URLSearchParams(window.location.search)
    const user_id = params.get('user_id')
    const authorization = params.get('authorization')

    if (user_id && authorization) {
      App.notification({
        title: gt('waiting_message'),
        message: gt('auto_login_message')
      })

      form.inputs.user_id.value = user_id
      form.inputs.authorization.value = authorization
      login()
    }
  }

  onMounted(() => {
    auth_by_token()
  })
</script>

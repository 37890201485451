import env from '@/../env.json'

export default () => {
  const query_params = new URLSearchParams(window.location.search)

  env.utm_variables.forEach((key) => {
    const param_value = query_params.get(key)
    const stored_value = localStorage.getItem(key)

    if (param_value && (!stored_value || stored_value === '')) {
      localStorage.setItem(key, param_value)
    }
  })
}

<template>
  <alert_component :level="ENV.enums.AlertLevels.DARK">
    <template #title> ¿Quieres continuar viendo esta ruta? </template>
    <template #message>
      <div class="block">
        Adquiere una suscripción para que puedas continuar obteniendo todos los
        beneficios que podemos ofrecerte, ¿te animas? ve al siguiente
        <a :href="page_to_suscribe" title="Suscribete"> enlace </a>
      </div>
    </template>
  </alert_component>
</template>
<script setup>
  // Components
  import { defineAsyncComponent } from 'vue'
  
  const alert_component = defineAsyncComponent(
    () => import('@/components/commons/alert.vue')
  )

  // Helpers
  import url from '@/helpers/url'

  // Enums
  import ENV from '@/../env.json'

  // Variables
  const page_pathname = window.location.pathname
  const page_to_suscribe = url({
    path: `/subscribe`,
    params: { redirect_to: page_pathname }
  })
</script>

// Services
import UserService from '@/services/user'

// Wrappers
import StoreWrapper from './helpers/wrappers/store'
import GetWrapper from './helpers/wrappers/get'
import DeleteWrapper from './helpers/wrappers/delete'

export default {
  /**
   * Fetches a list of resources.
   */
  async get(args = {}) {
    const { users, pagination } = await GetWrapper(new UserService().get, args)

    return {
      records: users || [],
      pagination
    }
  },

  /**
   * Stores a new resource.
   */
  async store({ $form, append = {} }) {
    return await StoreWrapper(new UserService().store, {
      $form,
      append
    })
  },

  /**
   * Deletes a resource.
   */
  async delete({ id }) {
    const { success, message } = await DeleteWrapper(new UserService().delete, {
      id
    })

    return {
      success,
      message
    }
  },

  /**
   * Deletes all notifications of a user by ID.
   */
  async remove_notifications({ id }) {
    const { success, message } = await DeleteWrapper(
      new UserService().remove_notifications,
      { id }
    )

    return {
      success,
      message
    }
  },

  /**
   * Fetches user permissions.
   */
  async get_permissions(args = {}) {
    const { permissions } = await GetWrapper(
      new UserService().get_permissions,
      args
    )

    return {
      permissions
    }
  }
}

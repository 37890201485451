// Wrappers
import StoreWrapper from './helpers/wrappers/store'
import GetWrapper from './helpers/wrappers/get'
import DeleteWrapper from './helpers/wrappers/delete'

// Services
import PostService from '@/services/post'

export default {
  /**
   * Fetches a list of resources.
   */
  async get(args = {}) {
    const { success, posts, pagination } = await GetWrapper(
      new PostService().get,
      args
    )

    return {
      success,
      records: posts || [],
      pagination
    }
  },

  /**
   * Stores a new resource.
   */
  async store({ $form, append = {} }) {
    return await StoreWrapper(new PostService().store, {
      $form,
      append
    })
  },

  /**
   * Stores a new resource.
   */
  async store_correlation({ $form, append = {} }) {
    return await StoreWrapper(new PostService().store_correlation, {
      $form,
      append
    })
  },

  /**
   * Update or store a new resource.
   */
  async update_post_like({ post_id }) {
    return await StoreWrapper(new PostService().update_like, {
      append: { post_id }
    })
  },

  /**
   * Fetch resources.
   */
  async get_correlations(args = {}) {
    const { success, correlations } = await GetWrapper(
      new PostService().get_correlations,
      args
    )

    return {
      success,
      correlations
    }
  },

  /**
   * Fetch a resource.
   */
  async get_stats(args = {}) {
    const { success, post, liked_by_me } = await GetWrapper(
      new PostService().get_stats,
      args
    )

    return {
      success,
      post,
      liked_by_me
    }
  },

  /**
   * Fetch resources.
   */
  async get_categories(args = {}) {
    const { success, categories } = await GetWrapper(
      new PostService().get_categories,
      args
    )

    return {
      success,
      records: categories || []
    }
  },

  /**
   * Fetch resources.
   */
  async get_ai_content(args = {}) {
    const { success, response } = await GetWrapper(
      new PostService().get_ai_content,
      args
    )

    return {
      success,
      response
    }
  },

  /**
   * Deletes a resource.
   */
  async delete({ id }) {
    const { success, message } = await DeleteWrapper(new PostService().delete, {
      id
    })

    return {
      success,
      message
    }
  },

  /**
   * Deletes a resource.
   */
  async delete_correlation({ id }) {
    const { success, message } = await DeleteWrapper(
      new PostService().delete_correlation,
      { id }
    )

    return {
      success,
      message
    }
  }
}

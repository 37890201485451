// Serializations
import units_serializer from '@/serializers/units'

// Service
import BaseService from '@/services/base'

export default class extends BaseService {
  constructor(args = {}) {
    super(args)
  }

  get = async (body = {}) => {
    return await this.execute({
      name: 'units.get',

      method: 'get',
      target: '/v1/units',
      body,

      serializers: {
        units: units_serializer
      },

      schema: {
        success: {
          required: ['units', 'pagination'],
          properties: {
            units: { type: 'array' },
            pagination: { type: 'object' }
          }
        }
      }
    })
  }

  store = async (body = {}) => {
    return await this.execute({
      name: 'unit.store',

      method: 'post',
      target: '/v1/unit',
      body
    })
  }

  update_progress = async (body = {}) => {
    return await this.execute({
      name: 'unit.update_progress',

      method: 'post',
      target: '/v1/unit/update/progress',
      body
    })
  }

  delete = async (body = {}) => {
    return await this.execute({
      name: 'route.delete',

      method: 'delete',
      target: '/v1/unit',
      body
    })
  }
}
